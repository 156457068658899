import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import styled from "styled-components";
import {Container} from "../styles/global"

const Privacy = ({location}) => (
    <Layout location={location}>
        <SEO title="Privacy Policy"/>
        <Container>
            <PrivacyTextGroup>
                <h1>
                    WEBSITE PRIVACY POLICY
                </h1>

                <p> This Privacy Policy applies to all personal information collected by Jinolo Pty Ltd via the website
                    located at www.jinolo.com (and its subdomains). </p>


                <h2> 1. What is "personal information"? </h2>
                <p>

                    (a) The Privacy Act 1988 (Cth) currently defines "personal information" as meaning information or an
                    opinion about an identified individual or an individual who is reasonably identifiable:

                    (i) whether the information or opinion is true or not; and

                    (ii) whether the information or opinion is recorded in a material form or not.

                    (b) If the information does not disclose your identity or enable your identity to be ascertained, it
                    will in most cases not be classified as "personal information" and will not be subject to this
                    privacy policy.
                </p>

                <h2> 2. What information do we collect? </h2>
                <p>

                    (a) The kind of personal information that we collect from you will depend on how you use the
                    website. The personal information which we collect and hold about you may include: User name,Company
                    name, email address and password .
                </p>

                <h2> 3. How we collect your personal information </h2>
                <p>

                    (a) We may collect personal information from you whenever you input such information into the
                    website.

                    (b) We also collect cookies from your computer which enable us to tell when you use the website and
                    also to help customise your website experience. As a general rule, however, it is not possible to
                    identify you personally from our use of cookies.
                </p>

                <h2> 4. Purpose of collection </h2>
                <p>

                    (a) The purpose for which we collect personal information is to provide you with the best service
                    experience possible on the website.

                    (b) We customarily disclose personal information only to our service providers who assist us in
                    operating the website. Your personal information may also be exposed from time to time to
                    maintenance and support personnel acting in the normal course of their duties.

                    (c) By using our website, you consent to the receipt of direct marketing material. We will only use
                    your personal information for this purpose if we have collected such

                    information direct from you, and if it is material of a type which you would reasonably expect to
                    receive from use. We do not use sensitive personal information in direct marketing activity. Our
                    direct marketing material will include a simple means by which you can request not to receive
                    further communications of this nature.
                </p>

                <h2> 5. Access and correction </h2>
                <p>
                    Australian Privacy Principle 12 permits you to obtain access to the personal information we hold
                    about you in certain circumstances, and Australian Privacy Principle 13 allows you to correct
                    inaccurate personal information subject to certain exceptions. If you would like to obtain such
                    access, please contact us as set out below.
                </p>

                <h2> 6. Complaint procedure </h2>
                <p>
                    If you have a complaint concerning the manner in which we maintain the privacy of your personal
                    information, please contact us as set out below. All complaints will be considered by Sophia
                    Kurianski and we may seek further information from you to clarify your concerns. If we agree that
                    your complaint is well founded, we will, in consultation with you, take appropriate steps to rectify
                    the problem. If you remain dissatisfied with the outcome, you may refer the matter to the Office of
                    the Australian Information Commissioner.
                </p>

                <h2> 7. Overseas transfer </h2>
                <p>
                    Your personal information may be transferred to recipients located in The United States of America.
                    The United States of America has data protection laws which protect personal information in a way
                    which is at least substantially similar to the Australian Privacy Principles, and there will be
                    mechanisms available to you to enforce protection of your personal information under that overseas
                    law. In the circumstances, we do not require the overseas recipients to comply with the Australian
                    Privacy Principles and we will not be liable for a breach of the Australian Privacy Principles if
                    your personal information is mishandled.
                </p>

                <h2> 8. GDPR </h2>
                <p>
                    In some circumstances, the European Union General Data Protection Regulation (GDPR) provides
                    additional protection to individuals located in Europe. The fact that you may be located in Europe
                    does not, however, on its own entitle you to protection under the GDPR. Our website does not
                    specifically target customers located in the European Union and we do not monitor the behaviour of
                    individuals in the European Union, and accordingly the GDPR does not apply.
                </p>

                <h2> 9. How to contact us about privacy </h2>
                <p>
                    If you have any queries, or if you seek access to your personal information, or if you have a
                    complaint about our privacy practices, you can contact us through: hello@jinolo.com.
                </p>


            </PrivacyTextGroup>
        </Container>
    </Layout>

)

export default Privacy

const PrivacyTextGroup = styled.div`

padding: 160px 20px 10px 60px;
  position: relative;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  // margin: 0;
  // padding: 20px;

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.navy};
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.navy};
  }

  p {
    color: ${props => props.theme.color.navy};
    margin-bottom: 24px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`